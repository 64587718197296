import { commonClient, publicCommonClient } from '../../../../interfaces/clients';

const getConsumerAppointments = async (userId) => {
  const { data: appointments } = await commonClient.get(
    `/api/appointments/consumer/${userId}`,
  );

  return appointments;
};

const cancelAppointment = async (params, dataToUpdate) => {
  const { appointmentId, consumerId } = params;
  const { data: updatedAppointment } = await commonClient.patch(
    `/api/appointments/${appointmentId}/consumer/${consumerId}`,
    dataToUpdate,
  );

  return updatedAppointment;
};

const updateAppointment = async (params, dataToUpdate) => {
  const { appointmentId } = params;
  return commonClient.patch(
    `/api/appointments/${appointmentId}`,
    dataToUpdate,
  );
};

const getProviderServiceOffer = async (params, query) => {
  const { providerId, centreId } = params;
  const { data: offer } = await publicCommonClient.get(
    `/api/providers/${providerId}/centres/${centreId}/services-day-offer`,
    { params: query },
  );

  return offer;
};

const createAppointment = async (data) => {
  const { data: appointment } = await commonClient.post('/api/appointments', data);

  return appointment;
};

export default {
  getConsumerAppointments,
  cancelAppointment,
  updateAppointment,
  getProviderServiceOffer,
  createAppointment,
};
