import { publicCommonClient } from '../../../interfaces/clients';

const getCentre = async (centreId) => publicCommonClient.get(`/api/centre/${centreId}/getCentre`);

const getCentreServices = async (centreId) => publicCommonClient.get(`/api/service/centre/${centreId}/getCentreServices`);

export const getBookingClinic = async (centreId) => {
  const { data: clinic } = await publicCommonClient.get(`/api/centres/${centreId}`);

  return clinic;
};

export const getAllClinicVariants = async (centreId) => {
  const { data: variants } = await publicCommonClient.get(
    `/api/services/centre/${centreId}/variant`,
  );

  return variants;
};

export const getAllClinicServices = async (centreId) => {
  const { data: services } = await publicCommonClient.get(
    `/api/centres/${centreId}/services`,
  );

  return services;
};

export const getClinicProviders = async (providerIds) => {
  const { data: response } = await publicCommonClient.post('/api/private/users', {
    userIds: providerIds,
  });

  return response.result;
};

export default {
  // getClinic,
  getCentre,
  getBookingClinic,
  getAllClinicVariants,
  // getAllClinicServices,
  getCentreServices,
  getClinicProviders,
};
